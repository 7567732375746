#root {
  font-family: "Open Sans";
}

p, h1, h2, h3, h4, h5, h6 {
  /* Fix for the most annoying user-agent stylesheet default */
  /* that bootstrap themselves seem to like... even though it gets in the way */
  margin: 0;
  padding: 0;
}

.header {
  height: 155px;
  background-color: #303030;
}