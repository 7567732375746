.status-card {
  /* This gives us a two-cell grid where the left cell takes up all */
  /* the space that the right cell does not need */
  display: grid;
  grid-template-columns: 1fr max-content;

  min-width: min-content;

  padding: 15px 20px 15px 30px;

  background-color: #FFFFFF;

  border: 2px solid #E4E4E4;
  border-radius: 10px;

  /* While we could use the bootstrap shadow, something about */
  /* it feels different than the designs... */
  box-shadow: 0px 3.25px 42px rgba(0, 0, 0, 0.1);
}

.status-card:enabled:hover {
  border-color: #BEBEBE;
}

.status-card-icon {
  border: 1px #E4E4E4 solid;
  border-radius: 10px;

  /* Keeps the content box the right size even if we have to deal with wrapped text */
  align-self: center;

  /* Clips image to rounded border */
  overflow: hidden;
}

.status-card-icon > img {
  width: 92px;
  height: 92px;

  /* Fill container along the image's long axis while preserving aspect ratio */
  /* https://developer.mozilla.org/en-US/docs/Web/CSS/object-fit#contain */
  object-fit: contain;
}

.text-project-name {
  /* fixes disable state graying out the project name */
  color: #303030;

  line-height: 36.5px;
  font-size: 26.75px
}

.text-operational {
  color: #25D16D;
}

.text-interruption {
  color: #F38335;
}