.status-cards {
  /* A grid of 400px tiles, unless the container is less than 400px wide */
  /* in which case make each tile full-width */
  /* In combination with a fluid container, this always gives */
  /* two columns unitl the small breakpoint */
  display: grid;
  grid-template-columns: repeat(auto-fit, min(400px, 100%));

  justify-content: center;

  margin-top: 65px;
  gap: 30px;
}

/* Bootstrap SMALL breakpoint */
@media(min-width: 576px) {
  .status-cards {
    margin-top: 172px;
    gap: 76px;
  }
}